import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createStore } from "vuex";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import axios from "axios";
import VueAxios from "vue-axios";
import i18n from "@/i18n";

await loadFonts();

// Create a new store instance.
export const store = createStore({
    state: () => ({
        formData: {
            advisor: {
                name: null,
                surname: null,
                code: null,
                contractForChild: null,
                contractNewOrOld: null,
                splitProvisionBool: null,
                splitProvision: null,
            },
            questionnaire: {
                q0: null,
                q1: null,
                q2: null,
                q3: null,
                q4: null,
                q5: null,
                q6: null,
                q7: {
                    sq1: [null, null, null, null, null],
                    sq2: [null, null, null, null, null],
                    sq3: [null, null, null, null, null],
                    sq4: [null, null, null, null, null],
                    pointsValue: null,
                },
                q8: null,
                q9: null,
                q10: null,
            },
            strategy: {
                recommendedStrategy: "", //40
                strategy: null,
                balancing: "ano",
                konzervativniPerc: 0,
                dluhopisovyPerc: 0,
                akciovyPerc: 0,
                monthlySavings: 1700,
                invProfil: null,
                customStrategy: false,
            },
            person: {
                contractStartDate: null,
                zakonnyZastupceBool: null,
                taxOptimalisationBool: null,
                jmenoDitete: null,
                prijmeniDitete: null,
                childBirthNumber: null,
                childBirthDate: null,
                childBirthPlace: null,
                childBirthState: null,
                childCitizenship: null,
                childGender: null,
                childAddressDiffersBool: null,
                diteAdresaStat: null,
                diteAdresaPsc: null,
                diteAdresaMesto: null,
                diteAdresaUlice: null,
                diteAdresaCislo: null,
                doBeneficiariesBool: null,
                obmysleneOsobyData: null,
                prispevekZamestnavatele: null,
                puvodPrijmu: null,
                cistyPrijem: null,
                nazevZamestnavatele: null,
                icoZamestnavatele: null,
                icoPodnikatele: null,
                odvetvi: null,
                otherIncomeSource: null,
                childNoBirthNumberBool: null,
            },
            transfer: {
                isTransformedFund: false,
                previousInsuranceCompany: null,
                previousContractNumber: null,
                transferType: null,
                reinvestmentMethod: null,
                reinvestedPercentage: 25,
                reinvestedCycles: 6,
            },
            summary: {
                titlePre: null,
                titlePost: null,
                name: null,
                surname: null,
                email: null,
                phonePrefix: null,
                phone: null,
                birthDate: null,
                birthNumber: null,
                birthPlace: null,
                birthState: null,
                citizenship: null,
                secondCitizenship: null,
                idType: null,
                idNumber: null,
                idIssuer: null,
                idValidity: null,
                idIssuedBy: null,
                permanentAddressState: null,
                permanentAddressZip: null,
                permanentAddressCity: null,
                permanentAddressStreet: null,
                permanentAddressNumber: null,
                permanentAddressEvidenceNumber: null,
                contactAddressDiffersBool: null,
                contactAddressState: null,
                contactAddressZip: null,
                contactAddressCity: null,
                contactAddressStreet: null,
                contactAddressNumber: null,
                taxResidencyState: null,
                TIN: null,
                isPepBool: false,
                pepPosition: null,
                pepState: null,
                pepRelationship: null,
            },
            documents: {},
            guid: null,
            idCode: null,
        },
        progressTimelineValues: 1,
        skipTimeline: false,
        error: null,
        token: null,
        signUrl: "await_url",
        selectedBank: {},
    }),
    getters: {
        formData: ({ formData }) => formData,
        progressTimelineValues: ({ progressTimelineValues }) =>
            progressTimelineValues,
        skipTimeline: ({ skipTimeline }) => skipTimeline,
        signUrl: ({ signUrl }) => signUrl,
        error: ({ error }) => error,
        token: ({ token }) => token,
    },
    mutations: {
        changeFormObject(state, payload) {
            state.formData = payload;
        },
        changeError(state, payload) {
            state.error = payload;
        },
        changeProgress(state, payload) {
            if (payload.skipTimeline) {
                state.progressTimelineValues = payload.progress;
                state.skipTimeline = payload.skipTimeline;
            } else {
                state.progressTimelineValues = payload;
            }
        },
        changeSignUrl(state, payload) {
            state.signUrl = payload;
        },
        saveToken(state, payload) {
            state.token = payload;
        },
    },
});

createApp(App)
    .use(router)
    .use(store)
    .use(vuetify)
    .use(i18n)
    .use(VueAxios, axios)
    .mount("#app");
