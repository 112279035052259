<template>
    <v-app>
        <AppHeader />
        <v-main style="padding-top: 0">
            <v-container class="main-form-wrapper">
                <router-view />
            </v-container>
        </v-main>
        <!--TODO Add colour variables (primary, background, text, etc.)-->
        <v-footer style="background: #0b369c; color: #eff2f8">
            <PageFooter />
        </v-footer>
    </v-app>
</template>

<script setup>
import PageFooter from "@/components/PageFooter";
import AppHeader from "@/components/AppHeader";
import { onBeforeMount } from "vue";
import router from "@/router";

onBeforeMount(() => {
    window.addEventListener("beforeunload", (event) => {
        if (
            router.currentRoute.value.path !== "/podpis" &&
            router.currentRoute.value.path !== "/identifikace" &&
            router.currentRoute.value.path !== "/" &&
            router.currentRoute.value.path !== "/hotovo"
        ) {
            event.preventDefault();
            // Chrome requires returnValue to be set.
            event.returnValue = "";
        }
    });
});
</script>

<style>
/*FONT DECLARATIONS*/
@font-face {
    font-family: "Tex-Gyre-Adventor";
    src: local("texgyreadventor-regular"),
        url(./fonts/texgyreadventor-regular.otf);
}

@font-face {
    font-family: "Tex-Gyre-Adventor";
    src: local("texgyreadventor-bold"), url(./fonts/texgyreadventor-bold.otf);
    font-weight: bold;
}

@font-face {
    font-family: "Poppins";
    src: local("Poppins"), url(./fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
    font-family: "ITC-Bold";
    src: local("ITC Avant Garde Gothic Bold"),
        url(./fonts/ITC_Avant_Garde_Gothic_Pro_Bold.otf);
}

@font-face {
    font-family: "ITC-Medium";
    src: local("ITC Avant Garde Gothic Medium"),
        url(./fonts/ITC_Avant_Garde_Gothic_Pro_Book.otf);
}

h1,
.h1-like {
    color: #0b369c;
    margin-bottom: 25px;
    font-size: 30px;
    line-height: 35px;
    font-family: ITC-Bold, sans-serif;
}

h2,
h3 {
    color: #0b369c;
    font-family: ITC-Bold, sans-serif;
}

body,
.v-application,
.v-app-bar.v-toolbar {
    background: #eff2f8 !important;
    font-family: Poppins, sans-serif;
}

footer {
    display: flex;
    flex-direction: column;
}

p {
    font-size: 15px;
    line-height: 20px;
}

.main-form-wrapper {
    margin: auto;
    padding: 70px 80px;
    max-width: 1440px;
}

.footer-wrapper {
    margin: auto;
    padding: 0px 80px;
    max-width: 1440px;
}

.rentea-box-wrapper {
    background: #ffffff;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 30px 0;
    box-shadow: 4px 4px 20px 0 #21409a33;
    transition: 0.5s ease;
}

.rentea-control-buttons-wrapper {
    display: flex;
    margin: 0;
    width: 100%;
}

.rentea-radio-group {
    margin-bottom: 15px;
}

.rentea-radio-group .v-selection-control {
    margin-right: 40px;
}

.rentea-selector-button,
.rentea-selector-button[type="button"] {
    background-color: #e1e1f1;
    border: #b3b3d8 solid 2px;
    border-radius: 20px 0;
    padding: 5px 20px;
    line-height: 40px;
    color: #0b369c;
    text-transform: uppercase;
    font-weight: 700;
}

.rentea-selector-button-selected,
.rentea-selector-button:hover,
.rentea-selector-button-selected[type="button"],
.rentea-selector-button:hover[type="button"] {
    background: #0b369c;
    color: #eff2f8;
}

.rentea-textfield-holder .input {
    color: #0b369c;
    width: 100%;
    background-color: #eff2f8;
    padding: 4px;
    border-radius: 10px;
    border: #0b369c solid 3px;
}

.rentea-textfield-holder .input:focus {
    border: #0b369c solid 3px;
}

.rentea-selector-holder .selector {
    color: #0b369c;
    width: 100%;
    background-color: #eff2f8;
    padding: 4px;
    border-radius: 10px;
    border: #0b369c solid 3px;
}

.rentea-selector-holder .option {
    width: 10px;
}

.rentea-subheading {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
}

.rentea-steps-text {
    font-size: 15px;
    line-height: 20px;
}

.rentea-emphasized {
    color: #529546;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
}

.rentea-emphasized.disabled {
    color: #bdbdbd;
}

.rentea-steps-number {
    color: #0b369c;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    margin-right: 20px;
    font-family: ITC-Bold, sans-serif;
}

.rentea-steps-wrapper {
    display: flex;
    margin-bottom: 20px;
    text-align: justify;
    align-items: center;
}

.rentea-table {
    max-width: 75%;
    border: 1px solid #e1e1f1;
    border-radius: 10px;
    padding: 0;
    border-spacing: 0;
}

.rentea-table th:first-child {
    border-radius: 10px 0 0 0;
    text-align: left;
    padding-left: 10px;
}

.rentea-table th:last-child {
    border-radius: 0 10px 0 0;
}

.rentea-table th {
    padding: 15px;
}

.rentea-table thead {
    font-size: 12px;
    line-height: 16px;
    color: #5f6c6e;
    border-radius: 10px;
    border: none;
    background: rgba(225, 225, 241, 0.5);
}

.rentea-table tbody {
    font-size: 14px;
    line-height: 18px;
    color: #2b3a3d;
}

.rentea-table td {
    padding: 10px;
    border: #eff2f8 solid 1px;
}

.rentea-table .v-selection-control {
    justify-content: center;
}

.rentea-alert-text {
    font-size: 12px;
    line-height: 16px;
}

.rentea-border-bottom-questionnaire .v-label {
    border-bottom: 1px solid #b2b2b2;
}

.questionnaire-radio-group .v-label {
    white-space: normal;
}

#vycet-bank {
    font-weight: 700;
}

#app .v-card--variant-flat {
    background: none;
}

#app .rentea-progress-holder .v-progress-linear__background {
    background: #0b369c;
}

#app .rentea-progress-holder .v-progress-linear__determinate {
    background: #0b369c;
}

#app .bigButtonWrapper .v-btn__content,
#app .backButtonWrapper .v-btn__content,
#app .confirmButtonWrapper .v-btn__content {
    transform: translateY(-14px);
}

#app .v-label {
    /*white-space: normal;*/
    opacity: 1;
}

#app .questionnaire .v-selection-control.v-radio {
    margin-bottom: 10px;
    margin-top: 10px;
}

#app .rentea-strategy-card .v-slide-group__content .v-btn {
    color: #2b3a3d;
    background: #eff2f8;
    border-radius: 10px !important;
}

#app .rentea-strategy-card .v-card-text {
    color: #2b3a3d;
    background: #eff2f8;
}

/*#app .rentea-strategy-card .v-slide-group__content .v-btn.v-tab--selected {*/
/*    background: #0b369c;*/
/*    color: #ffffff;*/
/*}*/

#app .rentea-strategy-card .v-slide-group__content .v-btn__content {
    text-transform: none;
}

#app .v-alert {
    color: #2b3a3d;
    text-align: start;
    font-family: Poppins, sans-serif;
    font-size: 12px;
    line-height: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

#app .v-tab--selected .v-tab__slider {
    opacity: 0;
}

#app .v-input--disabled .v-field__outline {
    --v-field-border-width: 0px;
    border-bottom: solid 1px #a8a8bd;
}

#app .v-label,
#app .v-field__input {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    line-height: 18px;
    color: #9494ad;
}

#app .v-field__input {
    color: #2b3a3d;
    caret-color: #bcd543 !important;
}

#app .v-label:not(#app .v-field .v-label) {
    color: #2b3a3d;
    opacity: 1;
}

#app .v-field .v-field__overlay {
    display: none;
}

#app .v-input {
    margin-bottom: 0;
}

#app .v-field.v-field--active,
#app .v-field.v-field--variant-filled {
    background-color: rgb(238, 238, 247);
}

/*#app .v-switch--inset .v-switch__track {*/
/*    background-color: transparent;*/
/*    border: 2px solid;*/
/*}*/

/*#app .v-switch--inset .v-switch__track {*/
/*    background-color: transparent;*/
/*    border: 2px solid;*/
/*}*/

.v-field--variant-filled.v-field--focused .v-field__overlay {
    visibility: hidden;
}

#app .v-field.v-field--active .v-label {
    color: #9494ad;
}

#app .v-field.v-field--disabled {
    background-color: transparent;
    opacity: 1;
}

#app .v-field.v-field--disabled .v-field__overlay {
    visibility: hidden;
}

#app td .v-checkbox > .v-input__control {
    display: flex;
    justify-content: center;
}

#app .v-field.v-field--disabled .v-field__input {
    color: #2b3a3d;
    font-weight: 500;
}

#app .rentea-number-textfield .v-field .v-field__input input {
    text-align: right;
}

#app .v-field.v-field--disabled .v-field__outline::after {
    border-color: #bcd543;
}

#app .v-field--variant-filled .v-field__outline::after,
#app .v-field--variant-underlined .v-field__outline::after {
    border-color: #bcd543 !important;
}

#app .rentea-strategy-tabs .v-slide-group__content {
    padding-top: 50px !important;
}

#app .rentea-switch {
    margin-left: 5px;
}

#app .rentea-switch .v-switch__track {
    background: transparent;
    border: 1px solid #a3abac;
    height: 18px;
    width: 36px;
}

#app .rentea-switch .v-switch__thumb {
    border: 1px solid #a3abac;
    box-shadow: none;
}

#app .rentea-switch .v-selection-control--dirty .v-switch__thumb {
    border: 1px solid #bcd543;
    box-shadow: none;
}

#app .rentea-switch .v-selection-control--dirty .v-switch__track {
    border: none;
    background-color: #bcd543;
}

.rentea-slider {
    margin-bottom: 30px;
}

#app .rentea-slider .v-slider-track__fill,
#app .rentea-slider .v-slider-track__background {
    height: 12px;

    background-color: #0b369c;
}

#app .rentea-slider .v-slider-track__background {
    background-color: #e1e1f1;
}

#app .v-alert .v-alert__prepend .v-icon {
    color: #0b369c;
}

#app .rentea-strategy-card .v-slide-group__content .v-btn.v-tab--selected {
    background: #0b369c;
    color: #ffffff;
}

/* hide the "scrim", it's pointless */
.v-dialog .v-overlay--active .v-overlay__scrim {
    display: none;
}

/* style the overlay container as required */
.v-dialog.v-overlay--active {
    backdrop-filter: blur(2px);
    background: rgba(0, 0, 0, 0);
}

.rentea-strategy-card .v-slide-group__container {
    border-radius: 10px;
    /*z-index: 9999;*/
}

.v-dialog .v-overlay__content {
    max-width: 856px;
}

.v-dialog .v-overlay__content > .v-card > .v-card-text {
    padding: 60px !important;
}

.v-dialog .v-overlay__content > .v-card {
    border-radius: 30px !important;
}

.rentea-box-wrapper:not(.limit-width) .v-radio-group > .v-input__control {
    min-width: 80vw;
}

.v-tooltip.v-overlay > .v-overlay__content {
    background: #f8f8f8;
    -webkit-box-shadow: 7px 10px 30px -10px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 7px 10px 30px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 7px 10px 30px -10px rgba(0, 0, 0, 0.75);
    color: #2b3a3d;
}

@media only screen and (max-width: 600px) {
    .v-radio-group > .v-input__control {
        min-width: 80vw;
    }

    .footer-wrapper {
        padding: 0px 5px 15px;
    }

    .header {
        padding: 10px !important;
        height: 60px !important;
    }

    .header-container {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
    }

    .header-phone-number {
        transform: translateY(-3px);
    }

    .confirm-button {
        font-size: 12px !important;
        padding: 10px 40px !important;
    }

    .rentea-progress-timeline-holder {
        background-color: #eff2f8;
    }

    .rentea-strategy-recommended {
        top: -42px !important;
        left: 0 !important;
        font-size: 10px !important;
        padding: 5px 15px !important;
        line-height: 45px !important;
        width: 125px;
        height: 50px;
    }

    .main-form-wrapper {
        margin: auto;
        padding: 50px 15px 20px;
        max-width: 100%;
    }

    /*TODO Calc*/
    .rentea-selector-button {
        /*width: 72vw;*/
        line-height: 18px;
        padding: 5px 20px;
        font-size: 12px;
    }

    .rentea-steps-number {
        font-size: 40px;
    }

    .rentea-steps-text {
        font-size: 15px;
        text-align: initial;
    }

    .rentea-steps-column {
        padding-bottom: 0;
    }

    .rentea-steps-row-wrapper {
        flex-direction: column;
    }

    .rentea-steps-column-b {
        padding-top: 0;
    }

    .rentea-timeline-tab {
        margin-right: 3px !important;
    }

    .rentea-control-buttons-wrapper {
        justify-content: space-between;
        width: 100%;
    }

    #app .v-card-text {
        padding-right: 0;
    }

    #app .v-selection-control.v-radio {
        margin-bottom: 20px;
    }

    #app .v-label {
        overflow: visible;
    }

    #app .v-alert {
        margin-left: 16px;
        margin-right: 16px;
    }

    #app .switch-signature .v-label {
        white-space: normal !important;
    }

    #app .switch-signature {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .v-dialog .v-overlay__content > .v-card > .v-card-text {
        padding: 16px !important;
    }

    .rentea-hint-button-wrapper {
        flex-direction: column;
        text-align: justify;
    }
}

.v-tabs.v-slide-group--is-overflowing:not(.v-slide-group--has-affixes)
    .v-tab:first-child {
    margin-inline-start: 0 !important;
}
</style>
